<template>
  <div id="app">
    <v-header v-if="routerName!=='helpList'"></v-header>
    <router-view></router-view>
    <v-footer v-if="routerName!=='helpList'"></v-footer>
  </div>
</template>

<script>
import Header from './components/header.vue';
import Footer from './components/footer.vue';
export default {
  name: 'App',
  data () {
    return {
      routerName: ''
    };
  },
  components: {
    'v-header': Header,
    'v-footer': Footer
  },
  watch: {
    $route: {
      handler (to) {
        this.routerName = to.path.split('/')[1]
      },
      immediate: true,
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 0.88rem;
  width: 7.5rem;
  margin: 0 auto;
}
</style>
