<template>
  <div class="fourHundredAndFour">
    <img src="@/assets/image/404.png" alt="404">
    <p>404</p>
    <div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
      <el-button type="primary" size="small" @click="$router.push({ name: 'home' })">返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',
  data () {
    return {
      timeOut: ''
    }
  },
  mounted () {
    this.timeOut = setTimeout(() => {
      this.$router.push({ name: 'home' })
    }, 5000);
  },
  beforeDestroy () {
    clearTimeout(this.timeOut)
    this.timeOut = ''
  }
}
</script>

<style lang="scss" scoped>
.fourHundredAndFour {
  width: 100%;
  height: 100%;
  background: #f5f8fc;
  text-align: center;
  position: absolute;
  top: 0;
  img {
    margin-top: 15%;
    width: 210px;
  }
  p {
    margin-top: 24px;
    font-size: 21px;
    font-weight: 400;
    color: #b7c1cf;
    line-height: 29px;
    letter-spacing: 4px;
    margin-bottom: 24px;
  }
}
</style>